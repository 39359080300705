<template>
  <section class="project-grid pt-12">
    <div class="bg-green">
      <div class="constrain-2/3 grid grid-cols-2 gap-4 relative -top-12">
        <div
          v-for="(project, i) in payload.projekte"
          :key="project.id"
          v-view
          :class="{ 'in-left': i % 2 === 0, 'in-right': i % 2 === 1}"
          class="animate"
        >
          <div v-if="project.image && project.image.normal" class="aspect a169 cover">
            <Image :src="project.image" />
          </div>
          <h3 class="my-4" v-text="project.title" />
          <div v-html="project.text" />
          <jk-button :to="project.link">
            Mehr erfahren
          </jk-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Image from '@/components/utils/Image.vue';
import JkButton from '@/components/utils/jkButton.vue';

export default {
  components: { JkButton, Image },
  props: { payload: { type: Object, default: Object } },
};
</script>
